import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { trackLead } from 'services/engagementTracking'
import { createFbEventId, getFbcFbpValues } from 'services/engagementTracking/facebookPixel'
import esAPI from '../../services/esAPI/esAPI'
import { formatFormServerError } from '../../utils/forms/errors'

export const useUserContext = () => useContext(UserContext)

const fetchCurrentUser = (userToken) => esAPI('get', `/auth/me?userToken=${userToken || ''}`).then(({ user }) => user)

const fetchAvailableContent = () => esAPI('get', '/course/content-available')

const asyncNoop = () => Promise.resolve()

/**
 * Used to provide the current user details and the
 * register, sign in and sign out actions.
 */
export const UserContext = React.createContext({
  user: null,
  loading: true,
  lead: asyncNoop,
  signIn: asyncNoop,
  signOut: asyncNoop,
  checkEmail: asyncNoop,
  requestCreatePassword: asyncNoop,
  requestPwdReset: asyncNoop,
  refreshAvailableContent: asyncNoop,
  resetPassword: asyncNoop,
  clearUser: () => undefined,
})

export class UserProvider extends React.Component {
  fetchContentAndSetUser = (user) =>
    fetchAvailableContent().then((content) => {
      this.setState({ user, content, loading: false })
      return user
    })

  state = {
    user: this.props.user,
    loading: !this.props.user,
    clearUser: () => {
      this.setState({ user: null, content: null, loading: false })
    },
    lead: async (values) => {
      const eventId = createFbEventId()
      return await esAPI('post', '/lead-capture', { ...values, ...getFbcFbpValues(), eventId }).then(async () => {
        const user = await fetchCurrentUser()
        trackLead(values.source, eventId)
        if (['ide-12m', 'ide-3m', 'ide-full'].includes(values.source)) {
          return user
        }
        return this.fetchContentAndSetUser(user)
      })
    },
    checkEmail: (email) => esAPI('get', `/auth/check-email/${email}`),
    requestCreatePassword: (email) =>
      esAPI('post', '/auth/request-create-password', { email }).catch(formatFormServerError),
    requestPwdReset: (values) => esAPI('post', '/auth/request-password-reset', values).catch(formatFormServerError),
    resetPassword: (values) =>
      esAPI('post', '/auth/reset-password', values).then(this.fetchContentAndSetUser).catch(formatFormServerError),
    refreshAvailableContent: () => fetchAvailableContent().then((content) => this.setState({ content })),
  }

  render() {
    return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>
  }
}

UserProvider.propTypes = {
  /** Optionally pass in a user, useful for SSR */
  user: PropTypes.object,
  /** Children can access the user using the UserContext.Consumer */
  children: PropTypes.node.isRequired,
}
