// New ES Fase - 06/02/2025
// Not removing previous implementation for safety.
import {
  //   RAISING_FUNDS_FOR_BUSINESS,
  ACCELERATOR, //   FOUNDATION,
  //   STARTER, //SUBSCRIPTION,
} from '@containers/PurchaseApi/PurchaseContainer'

const basePath = '/entrepreneur-learning-mentoring-course/'

// const STARTER_ROUTE = basePath + STARTER
// const FOUNDATION_ROUTE = basePath + FOUNDATION
const ACCELERATOR_ROUTE = basePath + ACCELERATOR
// const RAINSING_FUNDS_BUSINESS_ROUTE = basePath + RAISING_FUNDS_FOR_BUSINESS
// const SUBSCRIPTION_ROUTE = basePath + SUBSCRIPTION

export const programLinks = {
  // subscription: { href: SUBSCRIPTION_ROUTE, title: 'Success Program' },
  // accelerator: { href: ACCELERATOR_ROUTE, title: 'Idea to Exit Masterclass' }, // Accelerator
  // foundation: { href: FOUNDATION_ROUTE, title: 'Foundation' },
  // raisingFundsForBusiness: {
  //   href: RAINSING_FUNDS_BUSINESS_ROUTE,
  //   title: 'Raising Funds for Business',
  // },
  // starter: { href: STARTER_ROUTE, title: 'Starter' },
}

export const navLinks = {
  // ...programLinks,
  // accelerator: { href: ACCELERATOR_ROUTE, title: 'Idea to Exit Masterclass' },
  ideaToExit: { href: ACCELERATOR_ROUTE, title: 'Idea to Exit' },
  conversations: { href: '/conversations', title: 'Conversations' },
  freeResources: { href: '/free-resources', title: 'Free Resources' },
  advice: { href: '/advice', title: 'Advice' },
  about: { href: '/about-martin-warner', title: 'About Martin' },
  course: { href: '/course', title: 'Account' },
}

export const navComponents = {
  freeResources: {
    key: 'freeResources',
    Component: () => (
      <a href="https://edu.entrepreneurseminar.com/es-free-resources" target="_blank" rel="noreferrer">
        Free Resources
      </a>
    ),
  },
}
