import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import LogoLink from '../../../../brand/Logo/LogoLink'
import PrimaryCTA, { SecondaryCTA } from '../../../../input/Button'
import Nav from '../../../../navigation/Nav/Nav'
import NavItem from '../../../../navigation/Nav/NavItem'
import { navLinks } from '../navLinks'

const desktopNavLinks = [navLinks.ideaToExit, navLinks.conversations, navLinks.freeResources, navLinks.about]

const Container = styled.div`
  flex: 1;
  background: ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${(props) => props.theme.componentHeights.headerDesktop}px;
  padding: 0 20px;
`

const SiteLinks = styled(Nav)``

const UserLinks = styled(Nav)`
  flex-wrap: nowrap;
  margin-left: 20px;
`

const AccountButton = styled(PrimaryCTA)`
  margin-right: ${(props) => props.theme.spacing.md}px;
`

export const HeaderDesktop = ({ user, signOut }) => (
  <Container>
    <div>
      <LogoLink />
    </div>

    <SiteLinks>
      {/* <NavItem>
        <ProgramsDropdown />
      </NavItem> */}
      {desktopNavLinks.map(({ href, title, Component, key = title }) => (
        <NavItem key={key}>
          {Component ? (
            <Component />
          ) : (
            <Link href={href}>
              <a>{title}</a>
            </Link>
          )}
        </NavItem>
      ))}
    </SiteLinks>

    <UserLinks>
      {user && (
        <Link href="/course">
          <AccountButton>Account</AccountButton>
        </Link>
      )}
      <NavItem>
        {user ? (
          <SecondaryCTA onClick={signOut}>Log Out</SecondaryCTA>
        ) : (
          <Link href="/sign-in">
            <SecondaryCTA>Log In</SecondaryCTA>
          </Link>
        )}
      </NavItem>
    </UserLinks>
  </Container>
)
